<template>
  <div class="positionss">
    <Header/>
    <div class="screen" style="padding-top: 72px">
      <div class="screenWrap">
        <div class="inputSearch" style="width: 990px;margin-bottom: 16px;margin-top: 0">
          <img src="https://static.dasdt.cn/images/search.png" alt="">
          <div class="contentInput" style="width: 864px">
            <el-input v-model="input" placeholder="大家都在搜：JAVA" @blur="getPageJobs" @keyup.enter.native="getPageJobs"></el-input>
          </div>
          <div class="submit" @click="getPageJobs">搜索</div>
        </div>
        <div class="conditionWrap">
          <div class="conditionList">
            <el-dropdown trigger="hover" @command="eventType" placement="bottom">
              <div class="item" :class="{active: type !== ''}">
                <span v-if="type === ''">求职类型</span>
                <span v-if="type === 'trainee'">实习</span>
                <span v-if="type === 'college_recruitment'">校招</span>
                <span v-if="type === 'social_recruitment'">社招</span>
                <img v-if="type === ''" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu placement="bottom">
                <el-dropdown-item command="trainee">实习</el-dropdown-item>
                <el-dropdown-item command="college_recruitment">校招</el-dropdown-item>
                <el-dropdown-item command="social_recruitment">社招</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="choice"  placement="bottom">
              <div class="item" :class="{ active: educationCurrent !== '学历要求'}">
                <span>{{ educationCurrent }}</span>
                <img v-if="educationCurrent === '学历要求'" style="width: 8px;height: 4px"
                     src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in educationItems" :key="item.name" :command="item.name">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="eventExperience"  placement="bottom">
              <div class="item" :class="{ active: experience !== '工作经验'}">
                <span>{{ experience }}</span>
                <img v-if="experience === '工作经验'" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in experienceList" :key="item.name" :command="item.name">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown trigger="hover" @command="eventSalary" placement="bottom">
              <div class="item" :class="{ active: salaryTitle !== '薪资要求'}">
                <span >{{ salaryTitle }}</span>
<!--                <span v-else style="color: #3A76FF">{{ startSalary }}k - {{ // endSalary }}k</span>-->
                <img v-if="salaryTitle === '薪资要求'" style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down2.png">
                <img v-else style="width: 8px;height: 4px" src="https://static.dasdt.cn/images/down3.png">
              </div>
              <el-dropdown-menu placement="bottom">
                <el-dropdown-item :command="index" v-for="(item, index) in salaryList" :key="item.name">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="qingkong" style="position: relative;z-index: 9" @click="empty">清空条件</div>
        </div>
        <div class="tagListss">
          <div
              v-for="item in tagArr"
              :key="item.id"
              class="item"
              :class="{'checked-item': groupIds.includes(item.id)}"
              @click="select(item.id)"
          >
            {{ item.tag }}
          </div>
        </div>
      </div>
    </div>
    <div class="contentWrap">
      <div v-if="positionList.length!==0">
        <div class="positionList" v-loading="loading"

             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="transparent">
          <div class="item" v-for="(item, index) in positionList" :key="index">
            <div class="wrap" @click="goDetails(item.id)">
              <div class="nameOrPrice">
                <div class="name shenglue10">{{ item.job }}</div>
                <div class="pirce">{{item?.salaryNote}}</div>

              </div>
              <div class="middleWrap">
                <div class="tagList">
                  <!--                  <div class="items">{{ item.type }}</div>-->
                  <!--                  <div class="items">{{ item.education }}</div>-->
                  <div class="items" v-for="item in item.tagList" :key="item">{{ item }}</div>
                </div>
                <div class="enterpriseInfo">
                  <img style="border-radius: 6.67px" :src="item.logo.url" alt="">
                  <div class="right">
                    <div class="name">{{ item.companyName }}</div>

                    <div style="display: flex;">
                      <div class="tag" v-for="(item, index) in item.tags" :key="index">{{ item }} <span>·</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="toudi">投递简历</div>
              </div>
              <div class="infoList">
                <div class="itemss" v-if="item.areaName">
                  <img src="https://static.dasdt.cn/images/adress3.png">
                  <span>{{ item.areaName }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/education.png">
                  <span>{{ item.education }}</span>
                </div>
                <div class="itemss">
                  <img src="https://static.dasdt.cn/images/experience.png">
                  <span>{{ item.jobExp }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
              background
              layout="prev, pager, next, total"
              :total="totalCount"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-empty style="width: 900px;" v-else></el-empty>

      <div class="advertisementList">
        <img src="https://static.dasdt.cn/images/advertisement1.png">
        <img src="https://static.dasdt.cn/images/advertisement2.png">
        <img src="https://static.dasdt.cn/images/advertisement3.png">
        <img src="https://static.dasdt.cn/images/advertisement4.png">
      </div>
    </div>
    <floatRight/>
    <div style="margin-top: 20px">
      <Footer/>

    </div>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import Footer from "@/components/footer/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import {getTag, pageJobs} from "@/api/position";

export default {
  components: {floatRight, Footer, Header},
  data() {
    return {
      startSalary: '',
      endSalary: '',
      loading: false,
      input: '',
      value: 1,
      positionList: [],
      educationItems: [
        {name: "不限", value: ""},
        {name: "博士", value: "博士"},
        {name: "硕士", value: "硕士"},
        {name: "本科", value: "本科"},

        {name: "大专", value: "大专"},
      ],
      experienceList: [
        {
          name: "不限",
          value: "",
        },
        {
          name: "在校生",
          value: "在校生",
        },
        {
          name: "应届生",
          value: "应届生",
        },
        {
          name: "1年以内",
          value: "1年以内",
        },
        {
          name: "1-3年",
          value: "1-3年",
        },
        {
          name: "3-5年",
          value: "3-5年",
        },
        {
          name: "5-10年",
          value: "5-10年",
        },
        {
          name: "10年以上",
          value: "10年以上",
        },
      ],
      educationCurrent: '学历要求',
      type: '',
      experience: '工作经验',
      salary: '',
      salaryTitle: '薪资要求',
      salaryList: [
        {name: "不限", value: ""},
        {name: "3K以下", value: [0, 3]},
        {name: "3K-5K", value: [3, 5]},
        {name: "5K-10K", value: [5, 10]},
        {name: "10K-15K", value: [10, 15]},
        {name: "15K-20K", value: [15, 20]},
        {name: "20K-30K", value: [20, 30]},
        {name: "30K-50K", value: [30,50]},
        {name: "50K以上", value: [50, 100]},
      ],
      tagArr: [],
      groupIds: [],
      pagination: {
        number: 1,
        limit: 10
      },
      totalCount: 0,
      categoryOne: null,
      categoryTwo: null,
      categoryThree: null,
      job: '',
    }
  },
  mounted() {
    this.input = this.$route.query.searchParam ? this.$route.query.searchParam : ''
    scrollTo(0, 0)
    // this.getTag()
    this.getPageJobs()
  },
  watch: {
    params() {
      this.getPageJobs()
    },
    pagination: {
      handler: function () {
        this.getPageJobs()
      },
      deep: true
    },
  },
  computed: {
    params() {
      return {
        searchParam: this.input,
        education: (this.educationCurrent == '学历要求' || this.educationCurrent == '不限') ? '' : this.educationCurrent,
        jobExp: (this.experience == '工作经验' || this.experience == '不限') ? '' : this.experience,
        type: this.type,
        job: this.job,
        areaSearch: this.$store.getters.area.id,
        salary: this.salary === '' ? '' :this.salary,
        state: 'PUBLISHED'
      }
    }
  },
  methods: {

    handleCurrentChange(el) {
      this.pagination.number = el
    },
    async getPageJobs() {
      this.loading = true
      if (this.$route.query.info) {
        const info = JSON.parse(this.$route.query.info)
        if (info.level === 1) {
          this.params.categoryOne = info.id
        } else if (info.level === 2) {
          this.params.categoryTwo = info.id
        } else if (info.level === 3) {
          this.params.categoryThree = info.id
          // this.params.orderBy = [{'name':'createTime','direction':'DESC'}]
        }
      }
      // 职位名称
      if (this.$route.query.job) {
        this.params.job = this.$route.query.job
      }
      // 搜索框
      this.params.search = this.input
      this.clearBlankValue(this.params)
      let params = {}
      params = this.params
      if (this.params.salary && this.params.salary !== '') {
        params.lowSalary = this.params.salary[0]
        params.highSalary = this.params.salary[1]
        delete params.salary
      }
      delete this.params.salary
      this.params.auditState = 'SUCCESS'
      const res = await pageJobs(this.params, this.pagination)
      res.data.forEach((item) => {
        if (item.type === 'social_recruitment') {
          item.type = '社招'
        } else if (item.type === 'graduate_recruitment') {
          item.type = '校招'
        } else {
          item.type = '实习'
        }

      })
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        console.log(item.customFirmTag)
        if (item.customFirmTag) {
          item.firmTagList = [...item.firmTagList, ...item.customFirmTag]
        }
        if(item.tagList&&item.tagList.length>3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        if(item.tags&&item.tags.length>3) {
          item.tags = item.tags.slice(0, 3)
        }
      })
      console.log(res.data)
      this.positionList = res.data
      this.totalCount = res.totalCount
      this.loading = false
    },
    // 移除多余参数
    clearBlankValue(params) {
      const obj = JSON.parse(JSON.stringify(params))
      if (obj.searchParam === '') delete this.params.searchParam
      if (obj.education === '') delete this.params.education
      if (obj.jobExp === '') delete this.params.jobExp
      if (obj.type === '') delete this.params.type
      if (obj.job === '') delete this.params.job
      if (obj.search === '') delete this.params.search
      if (obj.areaSearch === '') delete this.params.areaSearch
      if (obj.salary === '') delete this.params.salary
      return this.params
    },
    // 获取标签
    async getTag() {
      const {data} = await getTag(10)
      this.tagArr = data
    },
    // 多选标签
    select(id) {
      if (this.groupIds.includes(id)) {
        const index = this.groupIds.indexOf(id)// 判断数组中是否有选中的id,如果有则去掉
        if (index > -1) {
          this.groupIds.splice(index, 1)
        }
      } else {
        this.groupIds.push(id)
      }
    },
    empty() {
      // this.educationCurrent = ''
      // this.experience = ''
      // this.type = ''
      // this.job = ''
      // this.keyword = ''
      window.location.href = "/position"
    },
    // 选择筛选条件
    choice(val) {
      this.educationCurrent = val
    },
    eventType(val) {
      this.type = val
    },
    eventExperience(val) {
      this.experience = val
    },
    eventSalary(index) {
      console.log(index)
      this.salary = this.salaryList[index].value
      this.salaryTitle = this.salaryList[index].name
    },
    goDetails(id) {
      this.$router.push(`/positionDetails?id=${id}`)
    }
  }
}
</script>
<style  lang="scss">
.el-dropdown-menu__item{
  text-align: center !important;
}
.positionss {
  background: #F5F5F6;
  overflow: hidden;


  .salary {
    display: flex;
    align-items: center;
    gap: 10px;

    .hengxian {
      margin-left: 10px;
      margin-right: 10px;
      width: 16px;

    }

    .title {
      color: #3A3A3A;
      font-size: 14px;

    }
  }

  .tagListss {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;

    .checked-item {
      border: 1px solid #2251a2 !important;
      background: #f2f6fa !important;
      color: #2251a2 !important;
    }

    .item {
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      //width: 100px;
      height: 32px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .contentWrap {
    position: relative;
    z-index: 9;
    margin: 24px auto;
    width: 1200px;
    display: flex;
    gap: 14px;

    .advertisementList {
      display: flex;
      flex-direction: column;
      gap: 14px;

      img {
        width: 286px;
        height: 114px;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .positionList::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .positionList {
      height: 650px;
      overflow-y: auto;
      width: 900px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;

      .item {
        border: 1px solid #fff;
        cursor: pointer;
        border-radius: 8px;
        width: 100%;
        background: #ffffff;

        .wrap {
          margin: 16px;

          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .itemss {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }

          .middleWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .toudi {
              background: #3A76FF;
              width: 88px;
              height: 34px;
              border-radius: 4px;
              cursor: pointer;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-weight: 400;

              &:hover {
                background: #3A76FF !important;
                color: #fff !important;
              }
            }

            .enterpriseInfo {
              flex: 1;
              margin-left: 30px;
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 40px;
                height: 40px;
              }

              .right {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .name {
                  font-size: 16px;
                  color: #282828;
                  font-weight: 500;
                }

                .tag {
                  color: #808080;
                  font-size: 14px;
                  line-height: 16px;
                  display: flex;

                  span {
                    display: none;
                  }
                }

                .tag:not(:last-child) {
                  span {
                    display: block !important;
                  }
                }
              }
            }

            .tagList {
              width: 356px;
              margin-top: 12px;
              display: flex;
              align-items: center;
              gap: 6px;

              .items {
                padding: 6px 12px;
                background: #F6F6F6;
                border-radius: 4px;
                color: #555555;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }

          .nameOrPrice {
            display: flex;
            align-items: center;
            gap: 14px;

            .name {
              color: #141414;
              font-size: 16px;
              font-weight: 500;
            }

            .pirce {
              color: #FD5056;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        &:hover {
          border: 1px solid #3A76FF;
        }
      }
    }
  }
}
</style>
